'use strict';

function BaseComponent(args) {
}

BaseComponent.initExtensions = function (moduleName, injector) {
    var extensions = angular.module(moduleName).extensions,
        exts = {};

    var normalizeName = function (extName) {
        return extName.replace('Ext', ''); // remove last 3 characters
    };

    for (var i = 0; i < extensions.length; i++) {
        var extName = extensions[i];
        try {
            exts[normalizeName(extName)] = injector.get(extName);
        } catch (e) {
            debugger;
        }
    }

    return exts;
};

BaseComponent.beInheritedBy = function (heirClass) {
    heirClass.prototype = Object.create(BaseComponent.prototype); // mixin integration

    Observer.call(heirClass.prototype);
};
