'use strict';

var EntryPointHelper = function () {
    function EntryPointHelper() {
    }

    /**
     * Initializer of EntryPointHelper
     * Bind to StructureReady and ConnClose events to show the welcome screen
     * This also shows the WelcomeScreen if needed
     */


    EntryPointHelper.prototype.init = function init() {
        CompChannel.on(CCEvent.StructureReady, function enableEntryPoint() {
            let windowHeight = window.outerHeight,
                windowWidth = window.outerWidth,
                showOnboardingMessage = false,
                requiredSize = SandboxComponent.requiredAmbientModeWindowSize();

            if ((windowWidth >= requiredSize.width && windowHeight >= requiredSize.height) ||
                (windowHeight >= requiredSize.width && windowWidth >= requiredSize.height) ||
                PlatformComponent.isElectron ||
                PlatformComponent.isWebInterface() ||
                PlatformComponent.isDeveloperInterface()
            ) {
                showOnboardingMessage = true;
            }

            if (!showOnboardingMessage) {
                Debug.AmbientMode && console.warn("Wanted!!!!! ---> can show ambient mode --> height: " + windowHeight);
                Debug.AmbientMode && console.warn("Wanted!!!!! ---> can show ambient mode --> width: " + windowWidth);
            }

            if (SandboxComponent.needToShowAmbientOnboarding() &&
                showOnboardingMessage) {
                NavigationComp.showEntryPointWelcomeScreen();
            }
        }.bind(this));
    };

    const _createControlLocationEntry = (ctrl, groupType) => {
        let descParts = [ctrl.getControlTypeName()];
        let name = ctrl.getName();
        if (groupType === GroupTypes.ROOM) {
            ctrl.getCategory() && descParts.push(ctrl.getCategory().name);
            if (name === ctrl.getRoom().name) {
                name = ctrl.name;
            }
        } else if (groupType === GroupTypes.CATEGORY) {
            ctrl.getRoom() && descParts.push(ctrl.getRoom().name);
        }
        return {
            name: name,
            description: descParts.filter(part => part !== name).join(SEPARATOR_SYMBOL),
            location: UrlStartLocation.CONTROL + "/" + ctrl.uuidAction
        }
    }

    const _getControlLocationsForGroup = (groupType, uuid) => {
        return ActiveMSComponent.getStructureManager().getControlsInGroup(groupType, uuid, false).map(ctrl => {
            return _createControlLocationEntry(ctrl, groupType);
        })
    }

    const _getFavoriteControlLocations = () => {
        return ActiveMSComponent.getStructureManager().getFavoriteControls().map((ctrl) => {
            // using category group type causes room info to be added as description
            return _createControlLocationEntry(ctrl, GroupTypes.CATEGORY);
        });
    }

    const _getCentralControlLocations = () => {
        let centralCtrls = [];
        // central controls come as an array of objects, where each entry consists of a room and a controls property.
        ActiveMSComponent.getStructureManager().getCentralControls().forEach(roomObj => {
            return centralCtrls.push(...roomObj.controls); // we're using the controls & discarding the rooms.
        });
        return centralCtrls.map((ctrl) => {
            // using category group type causes room info to be added as description
            return _createControlLocationEntry(ctrl, GroupTypes.CATEGORY);
        });
    }


    /**
     * Returns the possible locations as entry point
     * Possible options are all rooms and categories, tabs and last position
     * @returns {{description: {description, content: Array}, predefined: {content: [*,*]}, rooms: {name: *, content: [*]}, cats: {name: *, content: [*]}}}
     */
    EntryPointHelper.prototype.getLocationSources = function getLocationSources(withControls = false, roomCatSelectableOnRoot = true) {
        var rooms = ActiveMSComponent.getStructureManager().getGroupsByType(GroupTypes.ROOM),
            groupNames = ActiveMSComponent.getStructureManager().getCustomGroupTitles(),
            cats = ActiveMSComponent.getStructureManager().getGroupsByType(GroupTypes.CATEGORY),
            locationObject = {
                description: {
                    // will be shown in a separate section as footer.
                    description: HD_APP ? _("entry-point.location.definition.eco") : _("entry-point.location.definition"),
                    content: []
                },
                predefined: {
                    content: [
                        {
                            name: _("entry-point.location.last-location"),
                            location: UrlStartLocation.LAST_POSITION
                        },
                        {
                            name: _('favorites.title'),
                            location: UrlStartLocation.FAVORITES,
                            content: withControls ? _getFavoriteControlLocations() : null
                        },
                        {
                            name: _("central"),
                            location: UrlStartLocation.CENTRAL,
                            content: withControls ? _getCentralControlLocations() : null
                        }
                    ]
                }
            }; // Add this option only if the device has Loxone control and its enabled

        if (roomCatSelectableOnRoot) {
            locationObject.rooms = {
                name: groupNames[GroupTypes.ROOM],
                location: UrlStartLocation.ROOM,
                content: []
            }
            locationObject.cats = {
                name: groupNames[GroupTypes.CATEGORY],
                location: UrlStartLocation.CATEGORY,
                content: []
            }
        } else {
            locationObject.rooms = {
                name: groupNames[GroupTypes.ROOM],
                content: [{
                    name: _("groups.overview", {
                        groupsName: groupNames[GroupTypes.ROOM]
                    }),
                    location: UrlStartLocation.ROOM
                }]
            }
            locationObject.cats = {
                name: groupNames[GroupTypes.CATEGORY],
                content: [{
                    name: _("groups.overview", {
                        groupsName: groupNames[GroupTypes.CATEGORY]
                    }),
                    location: UrlStartLocation.CATEGORY
                }]
            }
        }

        if (LoxoneControl.hasLoxoneControl() || PlatformComponent.isDeveloperInterface()) {
            locationObject.predefined.content.push({
                name: _("wallmount.settings.presence-room").capitalize(),
                location: UrlStartLocation.LIKE_PRESENCE_DETECTION
            });
        }

        rooms.forEach((room) => {
            locationObject.rooms.content.push({
                name: room.name,
                location: UrlStartLocation.ROOM + "/" + room.uuid,
                content: withControls ? _getControlLocationsForGroup(GroupTypes.ROOM, room.uuid) : null
            });
        });
        cats.forEach((cat) => {
            locationObject.cats.content.push({
                name: cat.name,
                location: UrlStartLocation.CATEGORY + "/" + cat.uuid,
                content: withControls ? _getControlLocationsForGroup(GroupTypes.CATEGORY, cat.uuid) : null
            });
        });
        return locationObject;
    };
    /**
     * Returns the currently set location of the entryPoint
     * Note: Returns undefined if there is no location is available
     * @returns {*}
     */


    EntryPointHelper.prototype.getLocation = function getLocation() {
        var msSettings = PersistenceComponent.getMiniserverSettings();

        if (msSettings && msSettings.entryPointLocation) {
            return msSettings.entryPointLocation;
        }
    };
    /**
     * Returns the currently set location of the entryPoint as an UrlStart URL
     * Note: Will return the default location if the currently set entrypoint location is invalide
     * @returns {*}
     */


    EntryPointHelper.prototype.getLocationUrl = function getLocationUrl(customLocation) {
        var urlStart,
            entryPointLocation = customLocation || this.getLocation() || UrlStartLocation.FAVORITES,
            // default is favorites
            ms = ActiveMSComponent.getActiveMiniserver();

        try {
            urlStart = UrlHelper.createURLStart({
                mac: ms.serialNo
            }, entryPointLocation);
        } catch (e) {
            // Use default as fallback if no url could be created
            this.setEntryPointLocation(UrlStartLocation.FAVORITES);
            urlStart = this.getLocationUrl();
        }

        return urlStart;
    };
    /**
     * Sets the entryPoint location
     * @param location
     */


    EntryPointHelper.prototype.setEntryPointLocation = function setLocation(location) {
        PersistenceComponent.setEntryPointLocation(location);
    };

    /**
     * Resets the entryPoint location to favorites
     * @param location
     */
    EntryPointHelper.prototype.resetEntryPointLocation = function resetLocation() {
        PersistenceComponent.setEntryPointLocation(UrlStartLocation.FAVORITES);
    };
    /**
     * Returns true if a "last position" button is to be shown.
     * @return {boolean}
     */


    EntryPointHelper.prototype.needsEntryPointButton = function needsEntryPointButton() {
        var currLocation = this.getLocation(),
            needsButton = true;

        switch (currLocation) {
            case UrlStartLocation.LAST_POSITION:
                needsButton = false;
                break;

            default:
                break;
        }

        return needsButton; // will return either the group icon or a default icon.
    };
    /**
     * If the WelcomeScreen needs to be displayed
     * Note: Will return true if don't have an entryPointLocation and the WelcomeScreen hasn't been viewed yet
     * @returns {boolean}
     */


    EntryPointHelper.prototype.needsToShowWelcomeScreen = function needsToShowWelcomeScreen() {
        var msSettings = PersistenceComponent.getMiniserverSettings();

        if (HD_APP && msSettings) {
            if (!msSettings.welcomeScreenViewed) {
                return true;
            }
        }

        return false;
    };
    /**
     * Sets the WelcomeScreen as viewed
     * @param viewed
     */


    EntryPointHelper.prototype.setWelcomeScreenViewed = function setWelcomeScreenViewed(viewed) {
        PersistenceComponent.setWelcomeScreenViewed(viewed);
    };


    /**
     * Returns the name of the given location (Tabs, Rooms and Categories), will return null, if no name has been found
     * @param location
     * @returns {*}
     */
    EntryPointHelper.prototype.getLocationName = function getLocationName(location) {
        try {
            const entry = this.getLocationSourceEntry(location || EntryPointHelper.getLocation());
            return entry ? entry.name : null;
        } catch (ex) {
            console.error("EntryPointHelper", "getLocationName - failed for loc '"+ JSON.stringify(location) + "': " + JSON.stringify(ex));
            return null;
        }
    };

    EntryPointHelper.prototype.getLocationSourceEntry = function getLocationSourceEntry(location) {
        var locationSrc = this.getLocationSources(true),
            entry = null;

        const searchList = [ locationSrc.cats, locationSrc.rooms, ...locationSrc.predefined.content ];
        const recursiveSearch = (list) => {
            return list.some(locEntry => {
                if (locEntry.location === location) {
                    entry = locEntry;
                    return true;
                } else {
                    return locEntry.content ? recursiveSearch(locEntry.content) : false;
                }
            })
        }
        recursiveSearch(searchList);

        return entry;
    }


    /**
     * Compares the currently set location with a given location
     * @param locationUUID
     */
    EntryPointHelper.prototype.matchWithCurrentLocation = function matchWithCurrentLocation(locationUUID) {
        var currentLocation = this.getLocation();

        if (!currentLocation && locationUUID === UrlStartLocation.FAVORITES) {
            return true; // default is favorites
        }

        return this._matchWithLocation(currentLocation, locationUUID);
    };
    /**
     * Compares two given locations
     * @param location
     * @param locationUUID
     * @returns {boolean}
     */


    EntryPointHelper.prototype._matchWithLocation = function _matchWithLocation(location, locationUUID) {
        return location === locationUUID;
    };
    /**
     * Will return the icon for the group identified by the location string (either a room or a category)
     * @param location
     * @return {string}
     * @private
     */


    EntryPointHelper.prototype._getIconForGroup = function _getIconForGroup(location) {
        var prts = location.split("/"),
            type = prts[0],
            groupType = type === UrlStartLocation.ROOM ? GroupTypes.ROOM : GroupTypes.CATEGORY,
            uuid = prts[1],
            group = ActiveMSComponent.getStructureManager().getGroupByUUID(uuid, groupType);
        return group && group.image ? group.image : Icon.Menu.ENTRY_POINT;
    };

    return new EntryPointHelper();
}();
