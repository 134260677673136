'use strict';

/**
 * Class that tracks the network communciation across the app.
 * @constructor
 */

function CommTracker() {
    return {
        Transport: {
            HTTP: "HTTP",
            SOCKET: "Socket",
            DL_SOCKET: "DlSocket",
            HTTP_UNIV: "HTTP-univ",
            AUDIO_SOCKET: "Audio-Socket",
            AUDIO_HTTP: "Audio-HTTP",
            LXSVR: "LxServer",
            INTERCOM_GEN_2_SIGNALING: "Intercom Gen. 2 SigSock",
            INTERCOM_GEN_2_HTTP: "Intercom Gen. 2 HTTP",
            IMG_DL: "ImageTag"
        },
        name: "CommTracker",
        _commTracking: {},
        _trafficStartTimestamps: {},
        _trafficEndTimestamps: {},
        _trafficEndTimeouts: {},

        /**
         * Called when a MS Session is stopped.
         */
        reset: function reset() {
            this._commTracking = {};
            this._trafficStartTimestamps = {};
            this._trafficEndTimestamps = {};
            this._trafficEndTimeouts = {};
        },

        /**
         *
         * @param promise
         * @param transport
         * @param cmd
         * @param [payload] optional, if payload is transmitted besides the command itself.
         */
        track: function track(promise, transport, cmd, payload) {
            Debug.Communication && this.commStart(transport, cmd, payload);
            promise.then(function (res) {
                Debug.Communication && this.commFin(transport, cmd, false, res);
            }.bind(this), function (err) {
                Debug.Communication && this.commFin(transport, cmd, true, err);
            }.bind(this));
        },

        /**
         * Called each time something is passed to the commComp to be sent
         * @param transport which transport to use (HTTP, Socket, DLSocket)
         * @param cmd       the command to send on this transport
         * @param [payload] passed on if additional e.g. post payload is transmitted besides the cmd.
         * @private
         */
        commStart: function commStart(transport, cmd, payload) {
            this._commTracking[transport] = this._commTracking[transport] || {};
            this._commTracking[transport][cmd] = this._initCmdObj(cmd, payload);

            this._trafficStart(transport);

            console.log(this.name, this._logReq(transport) + this._logCmd(cmd));
        },

        /**
         * Called by the extensions when the command itself is sent  (also called when getkey or alike is sent)
         * @param transport which transport to use (HTTP, Socket, DLSocket)
         * @param cmd       the command to send on this transport
         * @param [fullRequest]   for http requests
         * @private
         * @returns {boolean} false if request sent is not tracked, true if it is.
         */
        commSent: function commSent(transport, cmd, fullRequest = null) {
            if (!this._commTracking || !this._commTracking[transport] || !this._commTracking[transport][cmd]) {
                // must be an internal command, as it hasn't been tracked via commStart.
                return false;
            }

            var trackingObj = this._commTracking[transport][cmd];
            trackingObj.sent = timingNow();
            trackingObj.fullRequest = fullRequest;

            this._compDurations(trackingObj);

            return true;
        },

        /**
         * Called each time a command that was sent has responded (success or error)
         * @param transport     which transport to use (HTTP, Socket, DLSocket)
         * @param cmd           the command to send on this transport
         * @param failed        true if the response was an error.
         * @param [response]    the payload passed on in the response
         * @private
         */
        commFin: function commFin(transport, cmd, failed, response) {
            if (!this._commTracking || !this._commTracking[transport]) {
                return;
            }
            var trackingObj = this._commTracking[transport][cmd];

            if (!trackingObj) {
                console.error(this.name, this._logResp(transport) + this._logCmd(cmd) + " - received response from untracked command!");

                this._trafficEnd(transport);

                return;
            }

            trackingObj.responded = timingNow();
            trackingObj.responseLength = Math.round(this._getPayloadLength(response) / 1024 * 100) / 100;

            this._compDurations(trackingObj);

            this._trafficEnd(transport);

            let suffix = "";
            if (trackingObj.fullRequest) {
                suffix = " (" + trackingObj.fullRequest + ")";
            }
            if (failed) {
                console.error(this.name, this._logResp(transport) + this._logCmd(cmd) + " - " + this._logDurations(trackingObj) + ", FAILED" + suffix);
            } else {
                console.log(this.name, this._logResp(transport) + this._logCmd(cmd) + " - " + this._logDurations(trackingObj) + suffix);
            }
        },
        // ----------------------------------------------------------------------------------
        // ------------------------------ Traffic Monitoring --------------------------------
        // ----------------------------------------------------------------------------------

        /**
         * Called each time something is sent over a transport, will store the start time
         * @param transport which transport to use (HTTP, Socket, DLSocket)
         * @private
         */
        _trafficStart: function _trafficStart(transport) {
            this._trafficStartTimestamps = this._trafficStartTimestamps || {};

            if (!this._trafficStartTimestamps[transport]) {
                this._trafficStartTimestamps[transport] = timingNow();
            }
        },

        /**
         * Called each time something is received over a transport
         * @param transport which transport to use (HTTP, Socket, DLSocket)
         * @private
         */
        _trafficEnd: function _trafficEnd(transport) {
            this._trafficEndTimestamps[transport] = timingNow();
            this._trafficEndTimeouts[transport] && clearTimeout(this._trafficEndTimeouts[transport]);
            this._trafficEndTimeouts[transport] = setTimeout(function () {
                this._startSummaryEndTimer();

                this._trafficStartTimestamps[transport] = false;
                this._trafficEndTimeouts[transport] = null;
            }.bind(this), 1500);
        },
        // ----------------------------------------------------------------------------------
        // ------------------------------  Summary Helper    --------------------------------
        // ----------------------------------------------------------------------------------
        _startSummaryEndTimer: function _startSummaryEndTimer() {//this._summaryTimeout && clearTimeout(this._summaryTimeout);
            //this._summaryTimeout = setTimeout(this._printSummary.bind(this), 3000);
        },
        // ----------------------------------------------------------------------------------
        // ------------------------------    Misc Helper     --------------------------------
        // ----------------------------------------------------------------------------------
        _initCmdObj: function _initCmdObj(cmd, payload) {
            return {
                start: timingNow(),
                sent: false,
                responded: false,
                delay: false,
                roundtrip: false,
                total: false,
                requestLength: Math.round((cmd.length + this._getPayloadLength(payload)) / 1024 * 100) / 100
            };
        },
        _compDurations: function _compDurations(trackingObj) {
            if (trackingObj.start && trackingObj.sent) {
                trackingObj.delay = Math.round(trackingObj.sent - trackingObj.start);
            } else {
                trackingObj.delay = false;
            }

            if (trackingObj.sent && trackingObj.responded) {
                trackingObj.roundtrip = Math.round(trackingObj.responded - trackingObj.sent);
            } else {
                trackingObj.roundtrip = false;
            }

            if (trackingObj.start && trackingObj.responded) {
                trackingObj.total = Math.round(trackingObj.responded - trackingObj.start);
            }

            if (trackingObj.total && trackingObj.responseLength) {
                var size = trackingObj.requestLength + trackingObj.responseLength;
                trackingObj.speed = size / (trackingObj.total / 1000); // kB per second
            }
        },
        _getPayloadLength: function _getPayloadLength(response) {
            var length = 0;

            if (!response) {
                return length;
            }

            try {
                var lengthProp = ["size", "length", "byteLength"].find(function (lengthProp) {
                    return lengthProp in response;
                });

                if (lengthProp) {
                    length = response[lengthProp];
                } else {
                    length = JSON.stringify(response).length;
                }
            } catch (ex) {
                length = 0;
            }

            return length;
        },
        // ----------------------------------------------------------------------------------
        // ------------------------------ Log Helper ----------------------------------------
        // ----------------------------------------------------------------------------------
        _isLxSvrTarget: function _isLxSvrTarget(transport) {
            var isLxSvr = false;

            switch (transport) {
                case CommTracker.Transport.LXSVR:
                    isLxSvr = true;
                    break;

                default:
                    break;
            }

            return isLxSvr;
        },
        _isAudioTarget: function _isAudioTarget(transport) {
            var isAudioTarget = false;

            switch (transport) {
                case CommTracker.Transport.AUDIO_SOCKET:
                case CommTracker.Transport.AUDIO_HTTP:
                    isAudioTarget = true;
                    break;

                default:
                    break;
            }

            return isAudioTarget;
        },
        _isIntercomGen2Target: function _isIntercomGen2Target(transport) {
            var isIntercomGen2Target = false;

            switch (transport) {
                case CommTracker.Transport.INTERCOM_GEN_2_SIGNALING:
                    isIntercomGen2Target = true;
                    break;

                case CommTracker.Transport.INTERCOM_GEN_2_HTTP:
                    isIntercomGen2Target = true;
                    break;

                default:
                    break;
            }

            return isIntercomGen2Target;
        },
        _counterPart: function _counterPart(transport) {
            if (this._isAudioTarget(transport)) {
                return "Audioserver";
            } else if (this._isLxSvrTarget(transport)) {
                return "LxServer";
            } else if (this._isIntercomGen2Target(transport)) {
                return "Intercom Gen. 2";
            } else if (transport === CommTracker.Transport.IMG_DL) {
                return "ImgSrc";
            } else {
                return "Miniserver";
            }
        },
        _logReq: function _logReq(transport) {
            var req = "App->" + this._counterPart(transport);

            return this._logT(transport) + " " + req + ": ";
        },
        _logResp: function _logResp(transport) {
            var resp = this._counterPart(transport) + "->App";
            return this._logT(transport) + " " + resp + ": ";
        },
        _logT: function _logT(transport) {
            return "[" + transport + "]";
        },
        _logCmd: function _logCmd(cmd) {
            return truncateString(cmd, 120);
        },
        _logDurations: function _logDurations(obj) {
            var res = "";

            if (obj.delay && obj.roundtrip) {
                res = "" + obj.delay + "+" + obj.roundtrip + "=" + obj.total + "ms";
            } else {
                res = "" + obj.total + "ms";
            }

            if (obj.responseLength && obj.requestLength) {
                var size = Math.round((obj.requestLength + obj.responseLength) * 100) / 100;
                res += ", RQ=" + obj.requestLength + "kB,RSP=" + obj.responseLength + "kB,Total:" + size + "kB";
            }

            if (obj.speed) {
                res += ", " + Math.round(obj.speed * 100) / 100 + "kB/s";
            }

            return res;
        },
        _printSummary: function _printSummary() {
            console.log(this.name, "_printSummary");
            this._summaryTimeout = null;
            var summary = {},
                sumObj,
                total = {};
            var logFormat = "   - %10.10s: #%04d - REQU=%06.02fkB,RESP=%06.02fkB - DEL=%06.02fms,RT=%06.02fms,TOTAL:%06.02fms";
            Object.keys(this._commTracking).forEach(function (transportKey) {
                sumObj = {};
                Object.values(this._commTracking[transportKey]).forEach(function (trackingObj) {
                    this._summarize(sumObj, trackingObj);
                }.bind(this));
                summary[transportKey] = sumObj;

                this._summarize(total, sumObj, true);
            }.bind(this));
            console.log(this.name, sprintf(logFormat, "TOTAL", total.trackings, total.requestLength, total.responseLength, total.delay, total.roundtrip, total.total));
            console.log(this.name, "----------------------------------------");
            Object.keys(summary).forEach(function (transportKey) {
                sumObj = summary[transportKey];
                console.log(this.name, sprintf(logFormat, transportKey, sumObj.trackings, sumObj.requestLength, sumObj.responseLength, sumObj.delay, sumObj.roundtrip, sumObj.total));
            }.bind(this));
            console.log(this.name, "Percentages: ");
            var percentageLogF = "   - %10.10s: #%03.04d% - REQU=%03.04d%,RESP=%03.04d% - DEL=%03.04d%,RT=%03.04d%,TOTAL:%03.04d%";
            Object.keys(summary).forEach(function (transportKey) {
                sumObj = summary[transportKey];
                console.log(this.name, sprintf(percentageLogF, transportKey, this._pctg(total.trackings, sumObj.trackings), this._pctg(total.requestLength, sumObj.requestLength), this._pctg(total.responseLength, sumObj.responseLength), this._pctg(total.delay, sumObj.delay), this._pctg(total.roundtrip, sumObj.roundtrip), this._pctg(total.total, sumObj.total)));
            }.bind(this));
        },
        _pctg: function _pctg(total, part) {
            return Math.round(part / total * 1000) / 10;
        },
        _summarize: function _summarize(sumObj, trackObj, isTotal) {
            this._summarizeProp(sumObj, trackObj, "requestLength", "maxRequestSize");

            this._summarizeProp(sumObj, trackObj, "responseLength", "maxResponseSize");

            this._summarizeProp(sumObj, trackObj, "delay", "longestDelay");

            this._summarizeProp(sumObj, trackObj, "roundtrip", "longestRoundtrip");

            this._summarizeProp(sumObj, trackObj, "total", "longestTotal");

            if (!sumObj.hasOwnProperty("trackings")) {
                sumObj.trackings = 0;
            }

            if (!!isTotal) {
                this._summarizeProp(sumObj, trackObj, "trackings", false);
            } else {
                sumObj.trackings++;
            }
        },
        _summarizeProp: function _summarizeProp(sumObj, trackObj, propertyKey, maxKey) {
            if (!!trackObj[propertyKey]) {
                if (!sumObj.hasOwnProperty(propertyKey)) {
                    sumObj[propertyKey] = 0;
                }

                sumObj[propertyKey] += trackObj[propertyKey];

                if (maxKey) {
                    if (!sumObj.hasOwnProperty(maxKey)) {
                        sumObj[maxKey] = 0;
                    }

                    sumObj[maxKey] = Math.max(sumObj[maxKey], trackObj[propertyKey]);
                }
            }
        }
    };
}
