'use strict';

/**
 * Requests Store ratings or feedback from the user
 * NOTE: The Webinterface is exempted from rating
 * 1. App is used for 120 minutes
 * 2. After the 120 minutes overall use the app must be active for over 60 seconds
 * 3. A Notification will appear
 * 4. If the user taps on the notification a popup will appear
 * 5. The user is able to directly rate the app in the popup
 *  a. The user votes for less or equal then 3 stars the Feedback form will appear
 *  b. The user votes for anything more (up to 5 stars) the AppStore Entry will appear
 *  c. If any of these options is chosen the rating is done and will start again in the next update
 *  d. The user may also chose "Rate later" or "Don't ask again". This option will flag the user as rated for this release until the next one
 * @constructor
 */

function RatingRequester() {
    var _minutesUntilRating = 240;
    return {
        // Enums
        WHITELISTED_LOCATIONS: [// To not disturb our user we only show this rating dialog on these screens
            UrlStartLocation.CENTRAL, UrlStartLocation.ROOM, UrlStartLocation.CATEGORY, UrlStartLocation.FAVORITES],
        MINUTES_UNTIL_RATING: _minutesUntilRating,
        // 120 minutes per design
        TIMEOUT_AFTER_CANCEL: 6,
        // Shows the rating notification two weeks after cancel
        MINUTES_UNTIL_RATING_COUNTDOWN_INTERVAL: 1,
        RATING_PROMPT_DELAY_IN_SECONDS: 60,
        // The notification should only appear when the app is used for at lease 60 seconds per design
        _APP_RATING_SETTINGS_ID: "Rating",
        _STORE_URLS: function () {
            var platformStoreMap = {};
            platformStoreMap[PlatformType.Android] = "http://play.google.com/store/apps/details?id=com.loxone.kerberos";
            platformStoreMap[PlatformType.IOS] = "itms-apps://itunes.apple.com/WebObjects/MZStore.woa/wa/viewContentsUserReviews?id=924399400&onlyLatestVersion=true&pageNumber=0"; // Directly opens the "Review" section of our App

            platformStoreMap[PlatformType.Mac] = "itms-apps://itunes.apple.com/WebObjects/MZStore.woa/wa/viewContentsUserReviews?id=1159613409&onlyLatestVersion=true&pageNumber=0"; // Directly opens the "Review" section of our App

            platformStoreMap[PlatformType.Windows] = "ms-windows-store://pdp/?productid=9PL9LVQGKKFR";
            return platformStoreMap;
        }(),
        _DEFAULT_RATING_URL: "https://www.loxone.com/enus/info/app-feedback/",
        _INTERNAL_FEEDBACK_EMAIL: "issue@loxone.com",
        // goes to the bug inbox
        _EXTERNAL_FEEDBACK_EMAIL: "support@loxone.com",
        // goes to partner coaching/support
        _BAD_RATING: 3,
        //======================================
        // Variables
        _isRunning: false,
        _currentRating: null,
        // The currently saved Rating object
        _defaultRatingObj: {
            version: PlatformComponent.getAppInfoObj().appVersion,
            minutesUntilRating: _minutesUntilRating,
            didAlreadyRate: false
        },
        _ratingCountdown: null,
        _ratingPromptTimeout: null,
        _ratingNotification: null,
        _currentLocation: null,
        //======================================
        // Public functions

        /**
         * Starts the rating timers defined by 'MINUTES_UNTIL_RATING' and 'RATING_PROMPT_DELAY_IN_SECONDS'
         * This function will also reset the ratingObject if an update is detected, this restarts the rating cycle...
         */
        start: function start() {
            // As per issue: https://projects.zoho.eu/portal/loxone#buginfo/16571000006777062/16571000007653063
            return;
            var platform = PlatformComponent.getPlatformInfoObj().platform; // Only start this once!

            if (platform !== PlatformType.Webinterface && !this._isRunning) {
                this._isRunning = true;
                this._currentRatingObj = this._getCurrentRatingObj(); // Set the default rating if no  this._currentRatingObj is available (initial installation or update from previous version)

                if (!this._currentRatingObj || isOlderVersionThan(this._currentRatingObj.version, PlatformComponent.getAppInfoObj().appVersion)) {
                    this._currentRatingObj = cloneObject(this._defaultRatingObj);

                    this._saveCurrentRatingObject();
                }

                this._handleDisplayRatingChecks();

                CompChannel.on(CCEvent.Pause, this.stop.bind(this));
                CompChannel.on(CCEvent.Resume, this._handleDisplayRatingChecks.bind(this));
                NavigationComp.registerForUIEvent(NavigationComp.UiEvents.UrlUpdate, function (ev, url) {
                    this._didNavigate(url);
                }.bind(this));
            }
        },
        stop: function stop() {
            this._stopRatingCountdown();

            this._stopRatingNotificationTimeout();

            this._isRunning = false;
        },
        //======================================
        // Private functions

        /**
         * Checks if the overall app usage has surpassed 30 minutes
         * @private
         */
        _handleDisplayRatingChecks: function _handleDisplayRatingChecks() {
            // Only show the rating notification on Release Versions
            if (UPDATE_LEVEL === UpdateComp.UpdateLevel.RELEASE) {
                if (!this._currentRatingObj.didAlreadyRate) {
                    if (this._currentRatingObj.minutesUntilRating > 0) {
                        this._startRatingCountdown();
                    } else if (this._shouldStartNotificationTimeout()) {
                        this._startRatingNotificationTimeout();
                    }
                }
            }
        },

        /**
         * Will decrease the minutesUntilRating value until 0 is reached
         * The decrease will account for unexpected app suspensions (Device Reboot, App Kill from Multitasking, ...)
         * @private
         */
        _startRatingCountdown: function _startRatingCountdown() {
            if (!this._ratingCountdown && this._currentRatingObj.minutesUntilRating > 0) {
                this._ratingCountdown = setInterval(function () {
                    // Decrease the minutesUntilRating by one minute every minute to account
                    // for unexpected app suspensions (Device Reboot, App Kill from Multitasking, ...)
                    this._currentRatingObj.minutesUntilRating -= this.MINUTES_UNTIL_RATING_COUNTDOWN_INTERVAL;

                    this._saveCurrentRatingObject();

                    this._handleDisplayRatingChecks(); // Stop the countdown to prevent the minutesUntilRating to be negative!


                    if (this._currentRatingObj.minutesUntilRating === 0) {
                        this._stopRatingCountdown();
                    }
                }.bind(this), this.MINUTES_UNTIL_RATING_COUNTDOWN_INTERVAL * 60 * 1000);
            }
        },

        /**
         * Stops the Rating countdown
         * @private
         */
        _stopRatingCountdown: function _stopRatingCountdown() {
            if (this._ratingCountdown) {
                clearInterval(this._ratingCountdown);
                this._ratingCountdown = null;
            }
        },

        /**
         * Starts the countdown to show the Rating notification
         * @private
         */
        _startRatingNotificationTimeout: function _startRatingNotificationTimeout() {
            if (!this._ratingPromptTimeout) {
                this._ratingPromptTimeout = setTimeout(this._showRatingNotification.bind(this), this.RATING_PROMPT_DELAY_IN_SECONDS * 1000);
            }
        },

        /**
         * Stops the countdown to show the Rating notification
         * @private
         */
        _stopRatingNotificationTimeout: function _stopRatingNotificationTimeout() {
            if (this._ratingPromptTimeout) {
                clearTimeout(this._ratingPromptTimeout);
                this._ratingPromptTimeout = null;
            }
        },

        /**
         * Shows the rating notification, a popup will appear if the user clicks on this notification
         * @private
         */
        _showRatingNotification: function _showRatingNotification() {
            this._stopRatingNotificationTimeout();

            if (this.WHITELISTED_LOCATIONS.indexOf(this._currentLocation) !== -1) {
                this._ratingNotification = GUI.Notification.createGeneralNotification({
                    title: _("app-rating.question"),
                    clickable: true,
                    closeable: true
                }, NotificationType.INFO);

                this._ratingNotification.on(GUI.Notification.CLICK_EVENT, function () {
                    this._ratingNotification.remove();

                    this.showRatingPopup();
                }.bind(this)); // Stop the rating process the next two weeks


                this._ratingNotification.on(GUI.Notification.MARK_AS_READ_EVENT, this._saveCurrentTimestamp.bind(this));
            } else {
                // If the location is not whitelisted we start all over again, so the user has to be on a whitelisted location when 30 seconds are passed
                this._handleDisplayRatingChecks();
            }
        },

        /**
         * Shows the actual rating popup, the user is encouraged to directly select his rating there,
         * we then decide what to do with this rating (Feedback or direct store rating)
         */
        showRatingPopup: function showRatingPopup(onlyShowCancelButton) {
            var content = {
                title: _("app-rating.question"),
                icon: Icon.HEART,
                availableStars: 5,
                buttonOk: onlyShowCancelButton ? false : _("app-rating.question.ask.later"),
                buttonCancel: onlyShowCancelButton ? true : _("app-rating.question.ask.never")
            };
            NavigationComp.showPopup(content, PopupType.RATING).then(function (rating) {
                if (rating !== GUI.PopupBase.ButtonType.OK) {
                    if (rating <= this._BAD_RATING) {
                        this._openFeedbackDialog(rating);
                    } else {
                        this._openAppStoreEntry();
                    }
                }
            }.bind(this), function () {
                if (!onlyShowCancelButton) {
                    this._markUserAsRated();
                }
            }.bind(this)); // Stopping the rater to prevent showing the notification in the background again

            this.stop();
        },

        /**
         * Gets the rating object saved in the localstorage
         * @returns {*}
         * @private
         */
        _getCurrentRatingObj: function _getCurrentRatingObj() {
            var ratingObj = null;

            try {
                ratingObj = JSON.parse(PersistenceComponent.getLocalStorageItem(this._APP_RATING_SETTINGS_ID));
            } catch (e) {// Do nothing
            }

            return ratingObj;
        },

        /**
         * Saves the rating object to the localstorage
         * @private
         * @return Promise
         */
        _saveCurrentRatingObject: function _saveCurrentRatingObject() {
            return Q(PersistenceComponent.setLocalStorageItem(this._APP_RATING_SETTINGS_ID, JSON.stringify(this._currentRatingObj)) || true);
        },

        /**
         * Marks the user as rated until the next version
         * @private
         * @return Promise
         */
        _markUserAsRated: function _markUserAsRated() {
            this._currentRatingObj.didAlreadyRate = true;
            return this._saveCurrentRatingObject();
        },

        /**
         * Get the current location if the URL is valid and save it to this._currentLocation
         * @private
         */
        _didNavigate: function _didNavigate(url) {
            if (url && typeof url === "string") {
                this._currentLocation = url.split("/").pop().toLowerCase();
            } else {
                this._currentLocation = null;
            }
        },

        /**
         * Opens the Store Entry for the correct platform, if no store is available a default URL is used
         */
        _openAppStoreEntry: function _openAppStoreEntry() {
            var storeURL = this._STORE_URLS[PlatformComponent.getPlatformInfoObj().platform] || this._DEFAULT_RATING_URL;

            this._markUserAsRated().done(function () {
                NavigationComp.openWebsite(storeURL, null, null);
            });
        },

        /**
         * Opens the mail application with a predefined recipient, subject and body
         * @param rating
         */
        _openFeedbackDialog: function _openFeedbackDialog(rating) {
            var mailToUrl = "mailto:" + this._EXTERNAL_FEEDBACK_EMAIL + "?subject=Feedback for Loxone App " + PlatformComponent.getAppInfoObj().appVersion + " - " + PlatformComponent.getLanguage() + "&body=" + _("app-rating.email.message", {
                rating: rating
            });

            this._markUserAsRated().done(function () {
                NavigationComp.openWebsite(encodeURI(mailToUrl), null, null);
            });
        },
        _saveCurrentTimestamp: function _saveCurrentTimestamp() {
            this._currentRatingObj.lastCancelled = moment().unix();

            this._saveCurrentRatingObject();
        },
        _shouldStartNotificationTimeout: function _shouldStartNotificationTimeout() {
            var shouldStart = false;

            if (!this._currentRatingObj.lastCancelled) {
                shouldStart = true;
            } else if (moment().diff(moment.unix(this._currentRatingObj.lastCancelled), 'week') >= this.TIMEOUT_AFTER_CANCEL) {
                shouldStart = true;
            }

            return shouldStart;
        }
    };
}
