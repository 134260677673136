'use strict';

window.Components = function (Components) {
    class Extension {
        /**
         * Base Extension Object
         * @param component this extension belongs to
         * @param extensionChannel using which extensions in this component communicate.
         * @constructor
         */
        constructor(component, extensionChannel) {
            Object.assign(this, EventHandler.Mixin);
            this.name = this.constructor.name;
            Debug.Architecture.Extensions && console.log("+", this.name, "ctor");

            if (!component || !extensionChannel) {
                throw "No component or extension channel given - this is required!";
            }

            this.component = component;
            this.channel = extensionChannel;
            this.regs = [];
        }

        /**
         * Called when an extension is destroyed
         */
        destroy() {
            for (var i = 0; i < this.regs.length; i++) {
                this.channel.off(this.regs[i]);
            }

            this.regs = null;
            this.component = null;
            this.channel = null;
        }

        /**
         * Register for a MediaServerComp extension channel event. It will unregister on destroy automatically
         * @param identifier    the ID of the event we want to know about
         * @param callbackFn    callback when the event is received.
         */
        registerExtensionEv(identifier, callbackFn) {
            this.regs.push(this.channel.on(identifier, callbackFn));
        }

    }

    Components.Extension = Extension; // EventHandler Mixin

    return Components;
}(window.Components || {});
